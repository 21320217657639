import React from "react";
import logo from "../assets/logo.png";
import loc from "../assets/loc.png";
import linkedin from "../assets/linkedin.png";
import twitter from "../assets/twitter.png";
const Footer = () => {
  return (
    <footer className="footer">
      <div>
        <div className="footer-logo">
          <img src={logo} alt="ZKY Logo" />
        </div>
        <div className="footer-social">
          <h4>Follow us</h4>
          <div className="social-icons">
            <a
              href="https://x.com"
              aria-label="X"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="" src={twitter} width={25} />
            </a>
            <a
              href="https://linkedin.com"
              aria-label="LinkedIn"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="" src={linkedin} width={25} />
            </a>
          </div>
        </div>
        <div className="footer-address">
          <h4 className="in-flex-center">
            <img alt="" src={loc} width={15} />
            Address
          </h4>
          <p>
            28, Bajanai Madam Street,, Manjini Nagar,Muthyialpet, Pondicherry,
            Puducherry, India 605003
          </p>
        </div>
      </div>
      <div className="footer-links">
        <div className="footer-section">
          <h4>Resources</h4>
          <ul>
            <li>Help center</li>
            <li>Help docs</li>
            <li>Developer docs</li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>Features</h4>
          <ul>
            <li>Affiliates</li>
            <li>PayPal Subscriptions</li>
            <li>Usage-based Billing</li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>Platform</h4>
          <ul>
            <li>Affiliates</li>
            <li>PayPal Subscriptions</li>
            <li>Usage-based Billing</li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
