import React from "react";
import o1 from "../assets/o1.png";
import i1 from "../assets/i1.png";
import i2 from "../assets/i2.png";
import v1 from "../assets/v1.png";
import v2 from "../assets/v2.png";
import v3 from "../assets/v3.png";
import v4 from "../assets/v4.png";
import FAQSection from "./Faq";
import Marquee from "react-fast-marquee";

const Product = () => {
  const imgs = [
    {
      label: "Profiles",
      img: v1,
    },
    {
      label: "Employments",
      img: v2,
    },
    {
      label: "Payouts",
      img: v3,
    },
    {
      label: "Documents",
      img: v4,
    },
  ];

  const cards = [
    {
      title: "Retail",
      description:
        "In the fast-paced retail world, quick and accessible background screening is essential. Our platform helps you hire smarter, reducing the risk of inventory loss and poor workplace culture.",
    },
    {
      title: "Technology",
      description:
        "In the tech industry, secure and swift background checks are crucial. Our advanced solutions help you hire the right talent, ensuring innovation and security at every step.",
    },
    {
      title: "Staffing",
      description:
        "Streamline your staffing process with our efficient background checks. Quickly place the best candidates, reduce hiring risks, and maintain a high-quality workforce.",
    },
    {
      title: "Banking",
      description:
        "Forget clunky verification processes. Team members can submit their details through a user-friendly mobile app, track results, and access instant in-app support.",
    },
    {
      title: "Manufacturing",
      description:
        "Ensure safety and reliability on your sites with our comprehensive background checks. From laborers to supervisors, we help you build a trustworthy team swiftly and efficiently.",
    },
    {
      title: "Healthcare",
      description:
        "When lives are at stake, trust our background checks to make informed hiring decisions, mitigate risks, and manage essential requirements for your healthcare organization.",
    },
  ];

  return (
    <>
      <section className="container products">
        <div className="head-sec">
          <span>Product</span>
          <h4>
            One platform, Customisable workflows to make the right checks in
            real-time, every time.
          </h4>
          <p>
            Our industry-leading depth and breadth of coverage means you’ll
            always have access to the data points you need.
          </p>
        </div>
        <div className="img-wrap">
          <img alt="" src={o1} />
        </div>
      </section>
      <section className="container verify">
        <div className="head-sec">
          <h4>Verify with the most complete data</h4>
          <p className="dim">
            Our platform offers an extensive array of verification services,
            including KYC validation, credit score analysis, education and
            employment verification, AML checks, and more.
          </p>
          <button
            className="btn-light mt-2"
            onClick={() => {
              window.open("https://sandbox.zky.co.in/request-demo", "_blank");
            }}
          >
            Get started for free
          </button>
        </div>
        <div className="cards">
          <Marquee pauseOnHover className="marquee-container">
            <ul>
              {imgs?.map((ele) => (
                <li>
                  <span>{ele?.label}</span>
                  <img alt="" src={ele.img} />
                </li>
              ))}
            </ul>
          </Marquee>
        </div>
      </section>
      <section className="container products instant">
        <div className="flex-between">
          <div className="head-sec">
            <span>FAST TURNAROUND TIMES</span>
            <h4>Precise and reliable verification Instantly.</h4>
            <p className>
              We provide background checks with some of the shortest turnaround
              times in the industry. This efficiency helps you expedite your
              verification processes, manage risks effectively, and keep your
              operations running smoothly.
            </p>
          </div>
          <img alt="" src={i1} />
        </div>
        <div
          className="flex-between"
          style={{
            flexDirection: "row-reverse",
          }}
        >
          <div
            className="head-sec"
            style={{
              marginLeft: "5rem",
            }}
          >
            <span>OMNI CHANNEL</span>
            <h4>Give candidates an experience to be proud of</h4>
            <p className>
              Forget clunky verification processes. Team members can submit
              their details through a user-friendly mobile app, track results,
              and access instant in-app support.
            </p>
          </div>
          <img alt="" src={i2} />
        </div>
      </section>
      <section className="container verify">
        <div className="head-sec">
          <span>INDUSTRIES</span>
          <h4>Trust and compliance for any industry.</h4>
          <p className="dim">
            Our platform is designed to cater to various industries, including
            workforce management, gig economy, hospitality, banking, and more.
          </p>
          <button
            className="btn-light mt-2"
            onClick={() => {
              window.open("https://sandbox.zky.co.in/request-demo", "_blank");
            }}
          >
            Get started for free
          </button>
        </div>
        <div className="cards industry">
          <ul>
            {cards.map((card, index) => (
              <li className="industry-card" key={index}>
                <div>
                  <h5>{card.title}</h5>
                  <p>{card.description}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>
      <section className="container products faq-sec">
        <FAQSection />
        <div className="get-in-touch">
          <div className="head-sec">
            <h4>Ready to enhance your onboarding?</h4>
            <p className="dim">
              Learn how Zky can help you elevate your employee background
              verification process. Our industry experts will get back to you
              within the day.
            </p>
            <button className="btn-light mt-2">Get in Touch</button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Product;
