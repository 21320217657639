import React from "react";
import close from "../assets/close.png";
import tick from "../assets/tick.png";
import academy from "../assets/academy.png";
import gaucho from "../assets/gaucho.png";
import iconic from "../assets/iconic.png";
import landify from "../assets/landify.png";
import typeheist from "../assets/typeheist.png";
import Marquee from "react-fast-marquee";

const Trusted = () => {
  return (
    <div>
      <section className="container trusted">
        <p>TRUSTED BY THOUSANDS OF COMPANIES IN 100+ COUNTRIES</p>
        <Marquee pauseOnHover className="marquee-container">
          <ul>
            <li>
              <img alt="" src={typeheist} />
            </li>
            <li>
              <img alt="" src={gaucho} />
            </li>
            <li>
              <img alt="" src={iconic} />
            </li>
            <li>
              <img alt="" src={academy} />
            </li>
            <li>
              <img alt="" src={landify} />
            </li>
          </ul>
        </Marquee>
      </section>
      <section className="why-br container">
        <div>
          <span>Why ZKY ?</span>
          <p className="ans">
            We delivers fast, accurate background checks with real-time access
            to extensive global databases, offering customisable solutions and
            robust security for reliable verification.
          </p>
          <p className="hero-subtitle">
            Say goodbye to outdated, manual screening methods and embrace 100%
            online automated checks. With Zky best-in-class, hassle-free
            products, you can deliver a seamless, standout experience for
            candidates every time.
          </p>

          <button
            className="btn-light mt-2"
            onClick={() => {
              window.open("https://sandbox.zky.co.in/request-demo", "_blank");
            }}
          >
            Get started for free
          </button>
        </div>
      </section>
      <section className="ways container">
        <div className="cards">
          <div className="card-container ow">
            <h3>The Old Way</h3>
            <ul className="list-unstyled">
              <li>
                <div className="icon">
                  <img alt="" src={close} width={16} />
                </div>
                <div>
                  <label>Slow And Manual. </label>
                  <span>
                    Requires Days (Or Weeks) Of Emails And Employer Cold Calls
                    To Gather Necessary Documentation.
                  </span>
                </div>
              </li>
              <li>
                <div className="icon">
                  <img alt="" src={close} width={16} />
                </div>
                <div>
                  <label>Few Data Points. </label>
                  <span>Access to limited information within reach.</span>
                </div>
              </li>
              <li>
                <div className="icon">
                  <img alt="" src={close} width={16} />
                </div>
                <div>
                  <label>Physical Process. </label>
                  <span>
                    This requires manual collection and validation of
                    information by an individual.
                  </span>
                </div>
              </li>
              <li>
                <div className="icon">
                  <img alt="" src={close} width={16} />
                </div>
                <div>
                  <label>Expensive. </label>
                  <span>
                    Involves Lot Of Manual Efforts And Time To Consolidate And
                    Validate Information, Which Costs More.
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <div className="card-container wz">
            <h3>With Zky</h3>
            <ul className="list-unstyled">
              <li>
                <div className="icon">
                  <img alt="" src={tick} width={16} />
                </div>
                <div>
                  <label>Fast And Automated. </label>
                  <span>
                    Takes Friction Out Of Verification And Enables Immediate
                    Data Access.
                  </span>
                </div>
              </li>
              <li>
                <div className="icon">
                  <img alt="" src={tick} width={16} />
                </div>
                <div>
                  <label>Comprehensive data set.</label>
                  <span>
                    {" "}
                    Includes a complete review of salary, ITR, Credit reports,
                    and 100+ datasets.
                  </span>
                </div>
              </li>
              <li>
                <div className="icon">
                  <img alt="" src={tick} width={16} />
                </div>
                <div>
                  <label>Complete Digital Process.</label>
                  <span>
                    Seamless Digital Journey for Collecting and Validating
                    Information from Various Bureaus via API
                  </span>
                </div>
              </li>
              <li>
                <div className="icon">
                  <img alt="" src={tick} width={16} />
                </div>
                <div>
                  <label>Budget Friendly.</label>
                  <span>
                    Automated Workflows And Fully Digital Verification Eliminate
                    Manual Intervention And Offer A Cost-Effective Solution.
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Trusted;
