import React from "react";
import logo from "../assets/logo.png";
import l1 from "../assets/l1.png";
import { useNavigate } from "react-router-dom";
const LandingPage = ({ fromContact }) => {
  const navigate = useNavigate();
  return (
    <div className="landing-page">
      {/* Header */}
      <header>
        <div className="container flex-between">
          <div className="nav-bar">
            <img
              src={logo}
              className="logo cursor"
              onClick={() => {
                navigate("/");
              }}
              alt=""
            />
            {/* <ul>
              <li className="nav-item">For Enterprises</li>
              <li className="nav-item">For Banks</li>
              <li className="nav-item">Resources</li>
              <li className="nav-item">Company</li>
            </ul> */}
          </div>
          <div>
            <ul>
              <li className="nav-item">Sign in</li>
              <li className="nav-item">
                <button
                  className="btn-light"
                  onClick={() => {
                    window.open(
                      "https://sandbox.zky.co.in/request-demo",
                      "_blank"
                    );
                  }}
                >
                  Get started
                </button>
              </li>
            </ul>
          </div>
        </div>
      </header>

      {/* Main Section */}
      {fromContact ? (
        <section className="hero-section">
          <div className="container">
            <div className="flex-between">
              <div className="hero-left">
                <h6 className="hero-title">
                  Let’s talk to our product experts
                </h6>
                <p className="hero-subtitle">
                  Building the future of finance is tough. But you don’t have to
                  do it alone. Reach out and one of our experts will be in touch
                  shortly to let you know how Bluerack can help.
                </p>
              </div>
              <div className="hero-form">
                <form
                  // ref={formRef}
                  onSubmit={(e) => {
                    // e.preventDefault();
                    // const userDetails = {
                    //   name: formRef.current?.username?.value,
                    //   email_id: formRef.current?.email_id?.value,
                    //   mobile_number: formRef.current?.mobile_number?.value,
                    //   description: formRef.current?.description?.value,
                    // };
                    // submittedDetails(userDetails);
                    // console.log(formRef.current?.username?.value);
                    // console.log(formRef.current?.email_id?.value);
                    // console.log(formRef.current?.mobile_number?.value);
                    // console.log(formRef.current?.description?.value);
                  }}
                >
                  <div>
                    <input placeholder="*Name" name="username" />
                  </div>
                  <div>
                    <input placeholder="*Email" name="email_id" />
                  </div>
                  <div>
                    <input placeholder="*Phone" name="mobile_number" />
                  </div>
                  <div>
                    <input
                      placeholder="*How can Bluerack Help ?"
                      name="description"
                    />
                  </div>

                  <label htmlFor="by-submit">
                    <p>
                      <input type="checkbox" name="opt_in" />
                      <span className="checkmark" />
                    </p>
                    <p>
                      By submitting this form you agree that your information
                      will be used in accordance with Privacy Policy
                    </p>
                  </label>

                  <button
                    style={{
                      marginTop: "2rem",
                      marginLeft: "2rem",
                    }}
                    type="submit"
                    className="btn-light"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="hero-section">
          <div className="container">
            <div className="hero">
              <h6 className="hero-title">
                Experience the Future of Background <br />
                Screening with <u>AI Precision</u>
              </h6>
              <p className="hero-subtitle">
                Our AI-powered platform enables us to provide accurate
                international background checks with the shortest turnaround
                times—with a real-time search of over 200,000+ databases.
              </p>
            </div>
            <div className="img-wrap">
              <img src={l1} alt="" />
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default LandingPage;
