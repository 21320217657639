import LandingPage from "./components/LandingPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Trusted from "./components/Trusted";
import Product from "./components/Product";
import Footer from "./components/Footer";

const Home = () => {
  return (
    <main>
      <div className="landing-sec">
        <LandingPage />
        <Trusted />
      </div>
      <Product />
      <Footer />
    </main>
  );
};

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/contact-us" element={<ContactUs />} /> */}
        <Route path="/" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
